export default {
  accounts: "/accounts",
  ads: "/ads",
  adsAssistant: "/ads-assistant",
  checkout: "/checkout",
  connectFailure: "/connect-failure",
  connectSuccess: "/connect-success",
  createAccount: "/create-account",
  createCampaign: "/create-campaign",
  editCampaign: "/edit-campaign",
  crm: "/crm",
  dashboard: "/dashboard",
  drive: "/drive",
  home: "/",
  login: "/login",
  generateAds: "/generate",
  metrics: "/metrics",
  newUser: "/new-user",
  posts: "/posts",
  // pricing: "/pricing",
  profile: "/profile/account", // This is the default route for the profile page
  profileAccount: "/profile/account", // Tab 1
  profileBusinessDetails: "/profile/business-details", // Tab 2
  profileAiAssistant: "/profile/aiAssistant", // Tab 3
  profileBilling: "/profile/billing", // Tab 4
  preferences: "/profile/preferences", // Tab 5
  signup: "/signup",
  support: "/support",
  tutorials: "/tutorials",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
  mindbodyLogin: "/mindbody-login",
};
